import { hwChannelHandler } from './channelHandlers/hw-channel-handler'
import { boChannelHandler } from './channelHandlers/bo-channel-handler'
import { repoChannelHandler } from './channelHandlers/repo-channel-handler'
import { chatChannelHandler } from './channelHandlers/chat-channel-handler'
import { log, warn } from '~/mixins/helpers'
import { mediaChannelHandler } from '@/mixins/packages/Shared/Client/handlers/echo/channelHandlers/media-channel-handler'

function parseChannelName (rawName) {
  const isLock = rawName.match(/^bo-lock\.([\w]*)\.([\d]*)/)
  const isMedia = rawName.match(/^media\.([\d]*)/)
  if (isLock) {
    return 'bo-lock'
  } else if (isMedia) {
    return 'media'
  } else {
    return rawName
  }
}

export default function channelHandler ({ $auth, $store, $repo, responce }) {
  log('[ChannelHandler][responce]', responce)

  const channelName = parseChannelName(responce?.channel)

  switch (channelName) {
    case 'hw':
      hwChannelHandler({ $store, responce })
      break
    case 'bo':
      boChannelHandler({ $auth, $store, $repo, responce })
      break
    case `repo.${$auth.user.userId}`:
      repoChannelHandler({ $auth, $store, responce })
      break
    case 'chat.admin':
      chatChannelHandler({ $auth, $store, responce })
      break
    case 'bo-lock':
      // log('[LOC-CHANEL]', { repo: ms[1], id: ms[2], responce })
      break
    case 'media':
      mediaChannelHandler({ $auth, $store, responce })
      break
    default:
      warn('Unknown channel', responce?.channel)
      break
  }
}
