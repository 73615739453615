import { log, warn } from '~/mixins/helpers'

export function mediaChannelHandler ({ $auth, $store, responce }) {
  const handler = new MediaChannelHandler($auth, $store, responce)

  log('MediaAction', responce?.action)
  switch (responce?.action) {
    case 'App\\Events\\MediaConvert':
      handler.convert()
      break
    default:
      warn('Unknown `chat` channel action', responce?.action)
      break
  }
}

class MediaChannelHandler {
  auth = null
  store = null
  responce = null

  constructor (auth, store, responce) {
    this.auth = auth
    this.store = store
    this.responce = responce
  }

  convert () {
    this.store.dispatch(
      'media/convertProgress',
      this.responce?.event
    )
  }
}
